export const encodeUrl = (url) => {
  const regex = /[^a-zA-Z0-9]+/g;
  const res = url.replace(regex, "-").replace(/-+/g, "-").replace(/^-|-$/g, "");
  return res;
};

export const transImgUrl = (url) => {
  const res = url
    .replace("static.utmel.com", "mychip-bk-0.oss-cn-shenzhen.aliyuncs.com")
    .replace("res.utmel.com", "mychip-bk-0.oss-cn-shenzhen.aliyuncs.com");
  return res;
};
